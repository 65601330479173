.watbied {
  a {
    font-size: inherit;
    color: $blue;
    letter-spacing: 0;
    cursor: pointer;
    user-select: none;
  }

  .healing,
  .meditatief,
  .psychic {
    text-align: center;
    margin-bottom: 70px;

    a {
      font-size: 22px;
    }

    .img-container {
      position: relative;
      height: 280px;

      img {
        width: 160px;
        height: 226px;
        display: block;
        margin: 0 auto 48px;
        transition: 300ms ease;
        position: absolute;
        left: calc(50% - 80px);
        transform-origin: center center;
        cursor:pointer;

        &:last-child {
          &:hover {
            opacity: 0;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-basis: 100%;
    }
  }

  .psychic {}

  .meditatief {}

  .healing {}
}
