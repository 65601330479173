@font-face {
  font-family: 'Exo Bold';
  src: 	url(assets/fonts/Exo-Bold.otf) format(opentype);
}

@font-face {
  font-family: 'Exo Regular';
  src: 	url(assets/fonts/Exo-Regular.otf) format(opentype);
}

@font-face {
  font-family: 'Exo Ligth';
  src: 	url(assets/fonts/Exo-Light.otf) format(opentype);
}
