.videos {
  height: 476px;
  background: grey;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  background: black;

  .image-container {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 346px;

    .video-container {
      display: none;
    }


    .image-container {
      display: block;

      img {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
    }
  }

  @media screen and (max-width: 768px) {}




  // max-width:1440px;
  .video-container {
    width: 100%;
    height: 100%;
    opacity: 0.6;

    .video {
      // display: none;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      &:first-child {
        display: block;
      }

      video {
        width: 100%;
        display: block;
      }
    }
  }

  .quote-container {
    position: absolute;
    text-align: center;
    z-index: 2;
    width: 80%;
    color: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h3 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
    }
  }
}
