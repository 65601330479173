@import url('https://fonts.googleapis.com/css?family=Exo:300,400,700');
@import 'partials/variables', 'partials/fonts', 'partials/heading', 'partials/nav', 'partials/videos', 'partials/waarom', 'partials/wiebenik', 'partials/watvoorpers', 'partials/watbiedik', 'partials/voorjonger', 'partials/modals', 'partials/footer', 'partials/blogPost';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $linkBlue;
  font-size: inherit;
  cursor: pointer;

  &:hover {
    color: $linkBlue;
  }
}

body {
  font-family: 'Exo', 'Roboto Slab', 'Open Sans', sans-serif;
  margin: 0;
  color: #464646;
}

img {
  max-width: 100%;
}

.clear {
  clear: both;
}

h2 {
  font-size: 44px;
  font-family: 'Exo';
  font-weight: 700;
  color: $blue;
  letter-spacing: 0;
}

p {
  font-size: 16px;
  color: #4B4B4B;
  letter-spacing: 0;
  line-height: 33px;
}

.section {
  padding: 64px 15px;
}

.quote {
  text-align: center;

  span {
    padding: 0 16px;
    color: $blue;
    font-size: 38px;
    background-image: $quoteBgGradien;
    // height: 96px;
    font-weight: 200;
    -webkit-box-decoration-break: clone !important;
    -ms-box-decoration-break: clone !important;
    -o-box-decoration-break: clone !important;
    box-decoration-break: clone !important;
  }

  small {
    float: right;
    font-size: 20px;
    font-weight: 700;
    margin-top: 16px;
    color: #1A82BF;
    letter-spacing: 0;
  }
}

.hide-desktop {
  display: none;
}
@media (max-width: 576px) {
  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }

  .section {
    padding: 0 16px !important;
  }

  .heading {
    height: auto;
    padding: 32px 0 !important;
  }

  .oval {
    display: none;
  }

  h2 {
    font-size: 22px;
  }

  .quote-container h3 {
    line-height: 1.6;
    font-size: 22px !important;
  }

  .waarom {
    .container {
      padding: 0 !important;
    }

    .waarom-list ul li {
      font-size: 16px;
      line-height: 1.6;

      &:before {
        width: 11px;
        margin-right: 4px;
      }
    }
  }

  .voorjongeren .col-md-8,
  .watvoor .col-md-8 {
    margin-top: 64px;
  }

  .footer {
    height: auto;
    padding-bottom: 32px;
    margin-top: 0px;

    ul {
      font-size: 14px;
    }
  }
}

.row {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.row > [class*='col-'] {
  display: flex !important;
  flex-direction: column !important;
}w

.blog.all-view {
  padding: 64px 0;
  overflow: hidden;

  .single-post {
    margin-bottom: 48px;

    h3 {
      font-size: 32px;
      color: #F2AC00;
      letter-spacing: 0;
      margin-bottom: 16px;
      font-weight: bold;
    }
  }
}


video {
  max-width: 100%;
}
.row > [class*='col-'] {
  max-width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {}
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {}
// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {}
