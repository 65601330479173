.oval.post {
  position: absolute;
  user-select: none;
  top: 550px;
  right: 0;
  opacity: 0.4;
  z-index: -1;
}

.single.blog-post {
  padding: 80px 0 64px;
  position: relative;
  overflow: hidden;

  .post-img {
    width: 100%;
    box-shadow: 0 0 32px 0 rgba(176,176,176,0.54);
    margin: 32px 0 64px;
  }

  .blog-post-title {
    font-size: 58px;
    margin-bottom: 80px;
    color: #f2ac00;
    letter-spacing: 0;
    font-weight: bolder;

    @media screen and (max-width: 515px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  .kop_titel {
    font-size: 18px;
    color: #0083ba;
    letter-spacing: 0;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .card-container {
    height: 1000px;
    .card {
      justify-self: flex-end !important;
      padding: 11px 16px 0px;
      background: #fff;
      border: 1px solid #f2ac00;
      box-shadow: 0 7px 37px 0 rgba(131,129,129,0.33);
      border-radius: 4px;
      top: 16px !important;
      position: sticky;
      transition: 300ms ease;

      .card-img {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .card-description {
        display: flex;
        align-items: center;
        margin-top: 8px;

        p {
          font-size: 12px;
          color: #4b4b4b;
          display: inline-flex;
          flex: 1;
          line-height: 16px;
        }

        .social-icons {
          list-style: none;

          li {
            display: inline-block;
            width: 22px;
            height: 22px;

            &:last-child {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
