.footer {
  // height: 385px;
  // background: $blue;
  background-image: linear-gradient(-147deg, #0083BA 0%, #00A6ED 100%);
  margin-top: 164px;

  h2 {
    color: $white;
    margin-top:64px;
    margin-bottom:32px;
  }

  ul {
    list-style: none;
    color: $white;
    line-height: 1.6;
    font-size:22px;

    li a {
      color: $white;
      text-decoration: underline;
    }
  }

  .portrait {
    transform: translateY(-87px);
    @media screen and (max-width: 768px) {
      transform: translateY(0px);
    }

    img {
      user-select: none;
    }

    label {
      color: white;
      font-weight: 300;
      text-align: center;
      width: 100%;
      font-size:12px;
      margin-top: 8px;

      .social-icons {
        list-style: none;
        li {
          display: inline-block;

          &:nth-child(1) {
              margin-right: 16px;
          }

          a {
            width: 22px;
            height: 22px;
            display: inline-block;
          }
        }
      }
    }
  }

  .modal h2 {
    color: $blue;
  }

  @media screen and (max-width: 768px) {
    // margin-top: 64px;
    padding-top: 16px;

    [class*='col'] {
      flex-basis: 100%;
    }

    h2 {
      margin: 16px 0;
    }
  }
}
