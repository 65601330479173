.waarom {
  position: relative;

  .oval {
    position: absolute;
    user-select: none;
    top: 0;
    right: 0;
    transform:translateY(-20%);
    z-index: -1;
  }

  .text-block {
    margin-top: 46px;

    svg {
      max-width: 500px;
      width:100%;
    }
  }

  .waarom-list {
    ul {
      list-style: none;
      margin-top: 8px;

      li {
        font-size: 18px;
        color: $textGray;
        line-height: 1.4;
        display: flex;

        &:before {
          content: url("../images/druppel-blauw-empty.svg");
          display: inline-block;
          margin-right: 16px;
          width: 14px;
        }
      }
    }
  }
}
