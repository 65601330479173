.modal {
  .modal-dialog {
    .cross {
      position: absolute;
      top:16px;
      right:16px;
      width: 16px;
      height:16px;
    }

    .modal-content {
      background: transparent;
      box-shadow: none;
      border: none;
      border-radius: 0;

      ul {
        padding-left:20px;
        font-size: 16px;
        line-height: 26px;
      }
    }

    &.modal-sm {
      padding: 32px 64px 64px;
      max-width: 960px;
      width:95%;
      background-color: $white;

      h2 {
        text-align: center;
      }

      @media (max-width: 576px) {
        padding:16px;
      }
    }

    &.modal-lg {
      padding: 32px 64px 64px;
      max-width: 1250px;
      width:95%;
      background-color: $white;

      h5 {
        font-size: 24px;
        color: $yellow;
      }

      .row {
        margin-top:32px;
      }

      @media (max-width: 576px) {
        padding:16px;
      }
    }
  }
}
