.nav {
  .menu {
    padding:0;
    margin: 0;
  }
  nav {
    background: #0583BB;
    width: 100%;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .menu-item {
      display: inline-flex;
      font-size: 18px;

      a {
        color: white;
        margin: 0 16px;
        transition: color 220ms ease-in-out;
        text-decoration: none;

        &:hover {
          color: #F2AC00;
        }
      }
    }
  }
}
